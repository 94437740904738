import React, { useState } from "react";
import { TextField, Button, MenuItem, Box, Typography, CircularProgress } from "@mui/material";
import { apiTestEmails } from "services/TestsService.js";
import Alerts from "utils/Alerts.js";

const emailTypes = [
    { value: 1, label: "Welcome Email" },
    { value: 2, label: "Offer Completion Notification" },
    { value: 3, label: "24 hours Email - First Offer" },
    { value: 4, label: "24 hours Email - Refer Friends" },
    { value: 5, label: "Notify Me - Offer Became Available" },
    { value: 6, label: "Coins Validated Notification" },
];

const EmailTestForm = () => {
    const [formData, setFormData] = useState({ notificationType: '', uniqueUserID: '', uniqueOfferID: '' });
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const res = await apiTestEmails(formData);
            if (res.code !== 1) {
                Alerts.showErrorAlert(res.message);
            } else {
                Alerts.showSuccessAlert(res.message);
            }
        } catch (error) {
            Alerts.showErrorAlert(error.message)
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: "#f9f9f9",
                padding: "20px",
                borderRadius: "8px",
                maxWidth: "600px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
        >
            {loading && (
                <Box className="loader">
                    <CircularProgress />
                </Box>
            )}
            <Typography variant="h5" sx={{ marginBottom: "20px", textAlign: "center" }}>
                Email Testing Form
            </Typography>
            <form onSubmit={handleSubmit}>
                {/* Email Type Dropdown */}
                <TextField
                    select
                    fullWidth
                    label="Select Email Type"
                    name="notificationType"
                    value={formData.emailType}
                    onChange={handleInputChange}
                    variant="outlined"
                    margin="normal"
                    required
                >
                    {emailTypes.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                            {type.label}
                        </MenuItem>
                    ))}
                </TextField>

                {/* Offer Unique ID */}
                <TextField
                    fullWidth
                    label="Offer Unique ID"
                    name="uniqueOfferID"
                    value={formData.offerUniqueID}
                    onChange={handleInputChange}
                    variant="outlined"
                    margin="normal"
                    required
                />

                <TextField
                    fullWidth
                    label="User Unique ID"
                    name="uniqueUserID"
                    value={formData.userUniqueID}
                    onChange={handleInputChange}
                    variant="outlined"
                    margin="normal"
                    required
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: "20px", padding: "10px 0" }}
                >
                    Send Email
                </Button>
            </form>
        </Box>
    );
};

export default EmailTestForm;
